import {units} from "./d_units";
const {uo_001, uo_002, uo_003, uo_004, uo_005} = units
const {uo_006, uo_007, uo_008, uo_009} = units
const {uo_010, uo_011, uo_012, uo_013} = units
export const organisers = {
  "os_001": {
    preTest: "",
    postTets: "",
    units : {uo_001, uo_002, uo_003, uo_004}
  },"os_002": {
    preTest: "",
    postTets: "",
    units : {uo_005, uo_006, uo_007}
  },"os_003": {
    preTest: "",
    postTets: "",
    units : {uo_008, uo_009}
  },"os_004": {
    preTest: "",
    postTets: "",
    units : {uo_010, uo_011, uo_012, uo_013}
  }
}