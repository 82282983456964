import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";

import ApplicationHome from "./applicationDashboard";
import ChannelDashboard from "./channelDashboard";
import StoreDashboard from "./store";
import Product from "./productPage";
import PaymentResponse from "./payments";

let Application = class Application extends Component {
  render() {
    return <Switch>
      <Route path="/application/paymentResponse/:txnid" component={ PaymentResponse }/>
      <Route path="/application/channel/:channelId" component={ ChannelDashboard }/>
      <Route path="/application/explore/:channelId" component={ Product }/>
      <Route path="/application/library" component={StoreDashboard}/>
      <Route path="/application/dashboard" component={ApplicationHome}/>
      <Route path="/application/" component={ApplicationHome}/>
    </Switch>
  }
}

export default Application;