import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import "./applicationDashboard.css";

import Capsule from "../../../components/MyCapsules"
import channelData from "../../../modals/d_channels";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
let applicationDashboard = class applicationDashboard extends Component {
  render() {
    let mySubscriptions = this.props.userState.mySubscriptions;
    let sorted = Object
      .keys(mySubscriptions)
      .length
      ? Object
        .keys(mySubscriptions)
        .sort((a, b) => {
          return (mySubscriptions[a].subscribedOn > mySubscriptions[b].subscribedOn
            ? 1
            : -1)
        })
      : null;
    let sorted_products = sorted
      ? sorted.map(id => {
        return id;
      })
      : null;
    return <div className="channelDashboardCntr" style={{
      width: '100%'
    }}>
      <Header active='myCapsule'/>
      <div className="channelsCntr">
        {sorted
          ? <div>
              <div
                className='capsule-cntr'
                style={{
                borderTop: 0,
                fontSize: "1.5rem"
              }}>My Capsules</div>
              {buildappDashboard(sorted_products, channelData)}</div>
          : <div
            style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div
              style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '4rem'
            }}>
              <div style={{
                margin: '1rem'
              }}>Capsules you have subscribed will appear here</div>
              <div style={{
                margin: '1rem'
              }}>Please subscribe to a capsule from 'Catalogue' for it to appear here</div>
              <a href='/application/library'>
                <div
                  className='action-btn active'
                  style={{
                  display: 'flex',
                  margin: '1rem'
                }}>Catalogue</div>
              </a>
            </div>
          </div>}
      </div>
      <Footer/>
    </div>
  }
}

const buildappDashboard = (order, channelData) => {
  return order.map(key => {
    let channel = channelData[key];
    return <Capsule key={key} cardData={channel}/>
  });
};

const mapStateToProps = state => ({userState: state.userState, auth: state.auth});

export default withRouter(connect(mapStateToProps, null)(applicationDashboard));