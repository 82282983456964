import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import channelData from "../../../modals/d_channels";
import {Player} from 'video-react';
import Header from "../../../components/Header";
import "video-react/dist/video-react.css";
import "./product.css";
import {subscribeAProduct} from "../store/redux_actions";
import Footer from "../../../components/Footer";

let product = class product extends Component {
  render() {
    let channelId = this.props.match.params.channelId;
    let channel = channelData[channelId];
    let userState = this.props.userState;
    let myAccount = this.props.myAccount;
    let subscribedChannels = {};
    Object
      .keys(userState.mySubscriptions)
      .forEach(el => {
        subscribedChannels[el] = true;
      });
    
    return <div style={{
      width: '100%'
    }}>
      <Header/>
      <div style={{margin:"-3rem 4rem 4rem", display:"flex", alignItems:"center", fontWeight:600, fontSize:'1rem'}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
        <Link to='/application/library'>Awaken! Catalogue</Link>
      </div>
      <div className="productPage">
        <div className="left-col">
          {/* <div className='ch-sub-title'>{channel.heading}</div> */}
          <div className='ch-title'>{channel.title}</div>
          <div className='ch-description'>{channel.excerpt}</div>
          <div className='recommendations'>
            <div
              style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}>
              <span className='-title'>Recommended for:</span>
              <span>{channel.recommendedFor || '10-14 years'}</span>
            </div>
            <div
              style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}>
              <span className='-title'>Duration:</span>
              <span>{channel.duration || '45mins'}</span>
            </div>
          </div>
          <div>
            <div className='-title'>What you will get?</div>
            {channel.offering}
          </div>
          <div
            style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '2rem'
          }}>
            {subscribedChannels[channelId]
              ? <div
                  className="action-btn disabled"
                  style={{
                  fontSize: '1rem'
                }}>Subscribed</div>
              : channel.ready?<div
                onClick={() => {
                subscribeAProduct(channelId, myAccount, channel.cost||250)
              }}
                className="action-btn active"
                style={{
                fontSize: '1rem'
              }}>{channel.cost||250} INR</div>:<div className="action-btn disabled">Coming Soon</div>}
          </div>
        </div>
        {channel.teaser?<div className="right-col">
          <Player playsInline poster={channel.poster} src={channel.teaser}/>
        </div>:null}
      </div>
      <Footer/>
    </div>
  }
}

const mapStateToProps = state => ({userState: state.userState, auth: state.auth});

export default withRouter(connect(mapStateToProps, null)(product));