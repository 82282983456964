export const activateComponent = ({courseId, value}) => {
  return dispatch => {
    dispatch({
      type: "ACTIVATE_COMPONENT",
      payload: {
        courseId,
        target: "milestone",
        value
      }
    })
  }
};