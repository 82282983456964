const INITIAL_STATE = {
  activeComponents : {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "ACTIVATE_COMPONENT":
      let {courseId, target, value} = action.payload;
      return {...state, activeComponents: {
        ...state.activeComponents, [courseId]:{
          ...state.activeComponents[courseId], [target]:value}
        }};
    default:
      return state;
  }
};
