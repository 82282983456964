import React, {Component} from "react";
import {Link} from "react-router-dom";
import Footer from '../../components/Footer'
let Science = class Science extends Component {
  render() {
    return <div style={{ overflow: 'auto', width:"calc(100vw - 10px)" }}>
      <div
        style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        zIndex: 100,
        width: '100%',
        backgroundColor: "#fff"
      }}>
        <div
          className="site-logo-cntr"
          style={{
          display: 'flex',
          marginLeft: '2rem'
        }}>
          <div style={{
            height: 72
          }}><img
            alt="illumine-logo"
            src='https://s3.ap-south-1.amazonaws.com/illumine-logos/logo_illumine.png'/></div>
          <div
            className='site-title'
            style={{
            flexGrow: 'initial'
          }}>
            <span
              style={{
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}>Illumine</span>
            <span className="merriwether red">Awaken!</span>
          </div>
        </div>
        <div className="--header" style={{
          display: "flex"
        }}>
          <div
            className="action-btn-cntr"
            style={{
            alignItems: "center"
          }}>
            <div>
              <a href="/#section-2" className="action-btn-text red">Vision</a>
            </div>
            <div>
              <a href="/#section-3" className="action-btn-text red">Capsules</a>
            </div>
            <div>
              <a href="/#section-5" className="action-btn-text red">Testimonials</a>
            </div>
            <div>
              <a href="/science" className="action-btn-text red">Science</a>
            </div>
          </div>
          <div className="action-btn active">
            <a target="_blank" href="https://goo.gl/forms/gjHxkLaOpreapYJn1" rel="noopener noreferrer">Join the Beta</a>
          </div>
          <div className="action-btn active inverted">
            <Link to="./auth/login">Sign In</Link>
          </div>

        </div>
      </div>

      <div style={{marginTop:"140px"}}>
        <div className="homepage-title merriwether red" style={{textAlign:"center",}}>Science behind Illumine Awaken! Capsules</div>
        <div style={{ maxWidth:"720px", margin:"auto", textAlign:"left"}}>
          <p style={{lineHeight:"1.5", fontSize:"1.25rem"}}>The design of “Awaken! Capsules” is based on more than 20 years of Illumine’s
            work in the Science of Cognitive Empowerment.</p>
            <br />
          <p style={{lineHeight:"1.5", fontSize:"1.25rem"}}>Cognitive Empowerment is the scientific approach to awakening people to their
            ‘inner strength’ in a systematic, rigorous and replicable manner at scale. It
            means strengthening people at a deeper cognitive level i.e. at the level of
            their vision of themselves and of life, frames or modes of thinking, belief
            system, cognitive capacities etc. Thereby leading to deeper and more sustainable
            changes in thinking within people.
          </p><br />
          <p style={{lineHeight:"1.5", fontSize:"1.25rem"}}>To explore more, visit the
            <a className="red" style={{lineHeight:"1.5", fontSize:"1.25rem"}} href="https://illumine.in/cognitive-empowerment.html"> Cognitive Empowerment </a>
            page of the Illumine website.</p>
        </div>
      </div>
      <Footer />
    </div>
  }
}
export default Science;