const INITIAL_STATE = {
  mySubscriptions: {},
  myAccount:{}
};

export default(state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "SYNC_LOCAL_FROM_GLOBAL":
      return {
        ...state,
        mySubscriptions: action.payload&&action.payload.mySubscriptions?action.payload.mySubscriptions:{},
        myAccount: action.payload&&action.payload.userAccount?action.payload.userAccount:{}
      };
    case "UPDATE_SUBSCRIPTION":
      let {subscriptionKey, productId, subscribedOn} = action.payload;
      return {
        ...state,
        mySubscriptions: {
          ...state.mySubscriptions,
          [subscriptionKey]:{
            ...state.mySubscriptions[subscriptionKey]||{},
            productId:productId,
            subscribedOn:subscribedOn
          } 
        }
      }
    default:
      return state;
  }
};