import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withStyles} from '@material-ui/core/styles';
import "./channelDashboard.css";

import LearningCycle from "../../../components/dashboard_learningCycleView";
import channelData from "../../../modals/d_channels";
import {activateComponent} from "./redux_actions";
import {Link} from "react-router-dom";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  }
});

let channelDashboard = class channelDashboard extends Component {
  render() {
    const { channelState } = this.props;
    let channelId = this.props.match.params.channelId;
    let channel = channelData[channelId];

    let activeStepId = channelState.activeComponents[channelId]
      ? channelState.activeComponents[channelId].milestone
      : Object.keys(channel.organisers.units)[0];
    let activeStep = activeStepId
      ? channel.organisers.units[activeStepId]?channel.organisers.units[activeStepId]:channel.organisers.units[Object.keys(channel.organisers.units)[0]]
      : channel.organisers.units[Object.keys(channel.organisers.units)[0]];
    let Layout = layout(activeStep.type, activeStep);
    return (
      <div className="_dashboardCntr">
        <div
          style={{
          margin: "1rem",
          display: "flex",
          alignItems: "center",
          fontWeight: 600,
          fontSize: '1rem'
        }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
          <Link to='/application/dashboard'>My Capsules</Link>
        </div>

        <div className="programMapCntr">
          <div style={{
            width: "25%"
          }}>
            <div
              style={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }}>
              <div className="programOverviewTrigger">
              {/* <div className="ch-sub-title">{channel.heading}</div> */}
              <div className="ch-title">{channel.title}</div>
              </div>
              <div className="program-flow-cntr">
                <LearningCycle
                  stepperstate={{
                  activeStepId
                }}
                  referenceId={channelId}
                  className="steps"
                  elements={channel.organisers}
                  onclick={(el) => {
                  this
                    .props
                    .activateComponent(el)
                }}/>
              </div>
            </div>
          </div>
          <div style={{
            width: "75%"
          }}>
            <div className="step-details-cntr">
              {Layout}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function layout(type, data) {
  let width = document.body.offsetWidth * 0.68;
  let height = width * 569 / 960;
  switch (type) {
    case 'ppt':
      return <iframe
        title="i-frame"
        src={data.src}
        frameBorder="0"
        width={width}
        height={height}
        allowFullScreen="true"></iframe>
    case 'tool':
      let Tool = data.tool;
      return <Tool/>
    default:
      return null
  }
}

const mapStateToProps = state => ({auth: state.auth, channelState: state.channelState});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    activateComponent
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(channelDashboard));