import React, {Component} from "react";
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import Glide from '@glidejs/glide';
import {Player} from 'video-react';
import Footer from '../../components/Footer'
// import {Input, InputLabel, FormControl, Button} from "@material-ui/core";
import "video-react/dist/video-react.css";
import "./public.css";
// import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

// let loginLink = props => <Link to="./auth/login" {...props}/>
// let registerLink = props => <Link to="./auth/register" {...props}/>

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: 'none'
  }
});

let Public = class Public extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      registedMsg: 'hidden'
    };

    this.handleChange = prop => event => {
      this.setState({[prop]: event.target.value});
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({
        showPassword: !this.state.showPassword
      });
    };
  }

  componentDidMount() {
    new Glide('.glide', {
      type: 'carousel',
      perView: 1,
      autoplay: 4000
    }).mount();
  }

  render() {
    return <div style={{ overflow: 'auto', width:"calc(100vw - 10px)" }}>
      <div
        style={{ display: 'flex', flexGrow: 1, alignItems: 'center',
        justifyContent: 'space-between', position: 'fixed', zIndex:100, width: '100%', backgroundColor:"#fff" }}>
        <div 
          className="site-logo-cntr"
          style={{
          display: 'flex',
          marginLeft: '2rem'
        }}>
          <div style={{
            height: 72
          }}><img
            alt="illumine-logo"
            src='https://s3.ap-south-1.amazonaws.com/illumine-logos/logo_illumine.png'/></div>
          <div
            className='site-title'
            style={{
            flexGrow: 'initial'
          }}>
            <span
              style={{
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}>Illumine</span>
            <span className="merriwether red">Awaken!</span>
          </div>
        </div>
        <div className="--header" style={{display:"flex"}}>
        <div className="action-btn-cntr" style={{ alignItems: "center" }}>
          <div><a href="#section-2" className="action-btn-text red">Vision</a></div>
          <div><a href="#section-3" className="action-btn-text red">Capsules</a></div>
          <div><a href="#section-5" className="action-btn-text red">Testimonials</a></div>
          <div><a href="./science" className="action-btn-text red">Science</a></div>
        </div>
        <div className="action-btn active"><a target="_blank" href="https://goo.gl/forms/gjHxkLaOpreapYJn1" rel="noopener noreferrer">Join the Beta</a></div>
        <div className="action-btn active inverted"><Link to="./auth/login">Sign In</Link></div>
        
        </div>
      </div>

      <div id='slideShow' style={{ width: '100%', marginTop:'100px'}}>
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide --slide"><img
                alt="illumine-slide-1"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider1.svg'/></li>
              <li className="glide__slide --slide"><img
                alt="illumine-slide-2"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider2.svg'/></li>
              <li className="glide__slide --slide"><img
                alt="illumine-slide-3"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider3.svg'/></li>
              <li className="glide__slide --slide"><img
                alt="illumine-slide-4"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider4.svg'/></li>
              <li className="glide__slide --slide"><img
                alt="illumine-slide-5"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider5.svg'/></li>
              <li className="glide__slide --slide"><img
                alt="illumine-slide-6"
                width="100%"
                src='https://s3.ap-south-1.amazonaws.com/possibilities-project/outcome-slideshow/slider6.svg'/></li>
            </ul>
          </div>

          <div className="glide__bullets" data-glide-el="controls[nav]">
            <button className="glide__bullet" data-glide-dir="=0"></button>
            <button className="glide__bullet" data-glide-dir="=1"></button>
            <button className="glide__bullet" data-glide-dir="=2"></button>
            <button className="glide__bullet" data-glide-dir="=3"></button>
            <button className="glide__bullet" data-glide-dir="=4"></button>
            <button className="glide__bullet" data-glide-dir="=5"></button>
          </div>
        </div>
      </div>

      <div
        id='section-2'
        style={{
        width: '100%',
        padding: '2rem 4rem',
        textAlign: 'center'
      }}>
        <div className='homepage-title merriwether red'>Prepare your child for the changing world by strengthening them from within</div>
        <img
          alt=""
          width="100%"
          src="https://s3.ap-south-1.amazonaws.com/possibilities-project/prep3levels-tree.svg"/>
      </div>

      <div
        id='section-3'
        style={{
        padding: '2rem 4rem',
        backgroundColor: '#fff7e9'
      }}>
        <div
          className="homepage-title merriwether red"
          style={{
          textAlign: 'center'
        }}>You can now systematically work along with your child towards this vision, one goal at a time, using “Illumine Awaken Capsules”</div>
        <div
          style={{
          display: 'flex',
          justifyContent: "space-around"
        }}>
          <div
            style={{
            width: '50%',
            padding: "2rem"
          }}>
            <img
              alt=""
              width="100%"
              src="https://s3.ap-south-1.amazonaws.com/possibilities-project/capsuletext.svg"/>
          </div>
          <div
            style={{
            width: '35%',
            display: "flex",
            alignItems: "center"
          }}>
            <Player
              playsInline
              poster="https://s3.ap-south-1.amazonaws.com/possibilities-project/walk-through-video-thumb.png"
              src="https://s3.ap-south-1.amazonaws.com/possibilities-project/video-with-music-v2.mp4"/>
          </div>
        </div>
      </div>
      <div
        id='section-4'
        style={{
        width: '100%',
        padding: '2rem 4rem',
        textAlign: 'center'
      }}>
        <div className='homepage-title merriwether red'>Some examples of goals you can meet with the Capsules…</div>
        <img
          alt=""
          style={{
          marginTop: "2rem"
        }}
          src="https://s3.ap-south-1.amazonaws.com/possibilities-project/home-middle-section.svg"/>
      </div>
      <div
        id='section-5'
        style={{
        width: '100%',
        padding: '2rem 4rem',
        textAlign: 'center',
        backgroundColor: '#fff7e9'
      }}>
        <div className='homepage-title merriwether red'>Parents and children who tried out some of our Capsules share their Illumine Awaken! experience</div>
        <img alt="" width="100%" style={{ padding:"0px 5rem" }} src="https://s3.ap-south-1.amazonaws.com/possibilities-project/testimonials.svg" />
      </div>
      <div id="section-6" style={{backgroundColor:"#FFBC36", textAlign:"center", padding: '2rem 4rem'}}>
        <div className='homepage-title merriwether'>We invite you to be a part of the Beta phase</div>
        <div className="homepage-subtitle libreFranklin" style={{fontSize:"1.125rem", marginTop:"-1rem", marginBottom: "2rem", maxWidth:"900px", margin:"auto"}}>In the Beta phase, Illumine Awaken! aims to scale this experience to a closed group of parents and children across the country, as our partners in taking this initiative forward</div>
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", }}>
        <img alt="" width="100%" style={{ maxWidth: "926px", margin: "auto", padding:"1rem 0rem" }} src="https://s3.ap-south-1.amazonaws.com/possibilities-project/join-the-beta.svg" />
        <a href="https://goo.gl/forms/gjHxkLaOpreapYJn1" target="_blank" rel="noopener noreferrer"><img alt="" width="100%" style={{ maxWidth: "926px", margin: "auto" }} src="https://s3.ap-south-1.amazonaws.com/possibilities-project/joinbetabutton.svg" /></a>
        </div>
      </div>
      <Footer/>
    </div>
  }
}

export default withStyles(styles)(Public)