import {organisers} from "./d_organisers";
import React from "react";
let mainRoute = "/application/channel";

const channels = {
  faceTheBrute:{
    heading: "Prepare for Life",
    title:"Face the Brutes",
    cost: 250,
    excerpt:<div>All of us have “brutes” in our life that stop us – “brutes” in the form of challenges, difficulties, fears. Our first instinct may be to avoid or run away from these “brutes” until they are too big to ignore. But if we choose to face & overcome them fearlessly, then we permanently free ourselves from the tyranny of these brutes in our life. <br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}>How do we face our brutes? Learn how, in this capsule.</span></div>,
    description: "Build your child’s capacity to confront and positively deal with challenges they have",
    badges: null,
    icon: "https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/Facethebrutes_icon.png",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_001,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FTB-thumb.PNG",
    teaser:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4",
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },developingConcentration:{
    heading:"Build Success Quotient",
    title: "Develop Concentration",
    cost: 450,
    excerpt: <div><blockquote>“There is no limit to the power of the mind. The more concentrated it is, the more power is brought to bear on one point.”</blockquote>(Swami Vivekananda)<br />This is the power of concentration and focus. <br /> It can help us excel in any activity we take up and achieve far higher levels of quality while spending far shorter time.  It can open up the deeper secrets of any subject, art, or profession, to us. <br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}>It is, therefore a key ingredient to success, which you can also systematically develop! Learn how, in this capsule.</span></div>,
    description: "Enable your child to systematically develop their power of ‘concentration’",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/concentration.png",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_003,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Concentration_thb.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/ConcentrationV4.mp4",
    ready: true,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },samesightedness:{
    heading:"Build Values",
    title: "Practice Same-sightedness",
    cost: 250,
    excerpt: <div>How do we see the people around us? Do we judge them and put them into narrow slots in our mind? Do we see them in ways that separates us from them, as being superior or inferior to us? OR can we see all people with a sense of equality because we are connected at a deeper level? The practice of same-sightedness can free us from ‘judging’ others and also ourselves. It can open our minds to all the different people around us.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Learn how to practice same-sightedness in this capsule.</span></div>,
    description: "Enable your child develop a sense of equality and deep respect for the different people around them, through the practice of ‘same-sightedness’",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/samesightedness.png",
    icon_bg: '#87D6A7',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/SameSightedness_thb.png",
    teaser: "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Samesightednessv7.mp4",
    ready: true,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },ICan:{
    heading:"Build Self-esteem",
    title: "I Can",
    excerpt: <div>Each one of us, carries within, a vast infinite source of power, strength and goodness. This inner power lies dormant until we step beyond what we know or have done before, and say “I Can!”. What does it mean to feel “I Can”?<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> How do we start developing our “I Can”? Explore in this capsule.</span></div>,
    description: "Build your child’s faith in themselves by developing an ‘I Can’ approach in them",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/ICan_icon.png",
    icon_bg: '#F4DF84',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/I_can_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },BeALion:{
    heading:"Build Self-esteem",
    title: "Be a Lion",
    excerpt: <div>Who am I? Am I a “sheep” who is mild, weak, and scared to step out of my “herd”? Or am I a “lion” – bold, strong, unafraid to stand alone or independent? If I believe I am a “sheep”, I easily fall prey to the pressures of my environment and get trapped in my circumstances. But if I awaken the “lion” within me, then my circumstances cannot hold me back - I can shape my circumstances and create my destiny.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover your “Lion Identity”, an identity of deep strength, in this capsule.</span></div>,
    description: "Awaken your child’s ‘Lion Identity’ within – an identity of deep strength, that can empower them to shape their circumstances and not get shackled by anything",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/BeaLion_icon.png",
    icon_bg: '#FFCE94',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/be_a_lion_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },ExpandOneself:{
    heading:"Build Citizenship",
    title: "Expand Oneself",
    excerpt: <div>Today you may see yourself as a child to your parents, a friend to your peers, a student of your school - but is that all you can be?  Can you expand yourself to be more?  - Can you take up bigger responsibilities like finding solutions to the small problems you see around you in your locality? Can you become an active citizen thinking about the big challenges facing our country?<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover the possibility of expanding yourself, in this capsule.</span></div>,
    description: "Expand your child’s vision of themselves – of who they can be, what they can be capable of, the extent of impact they could have, the extent of responsibility they could take on",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/ExpandMyself_icon.png",
    icon_bg: '#EDCCFF',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/ExpandOneself_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },ExerciseJudgment:{
    heading:"Build Knowledge Capacities",
    title: "Exercise judgment: Discriminating between ‘right’ and ‘wrong’",
    excerpt: <div>In today’s world, even from a young age we are exposed to an abundance of new information, choices and pressures from various sources: from the movies, TV, internet, from the people around us – our friends, teachers, parents. <br /> In all of this – how do we know what is the most appropriate or best choice to make in any situation? Can we learn to build a ‘sense of judgment’ so that we are able to think through for ourselves and make better choices?<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Learn how to exercise judgment, in this capsule.</span></div>,
    description: "Equip your child with the capacity to discern & develop their ‘sense of judgement’",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/Discrimination_icon.png",
    icon_bg: '#E2DF7D',
    organisers: organisers.os_004,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Discrimination_thb.png",
    teaser: null,
    ready: true,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },EngagePositively:{
    heading:"Engaging with the Environment",
    title: "Engage positively with people",
    excerpt: <div>Often our biases and fixed notions about people or specific communities of people, prevents us from engaging with them at all – we simply write them off as “not worth engaging with” in our minds.<br /> As this perpetuates, we may end up with only a narrow set of people we would be willing to engage with and develop a closed minded view of people. Worse, we may lose the chance to learn and grow from the different kinds of people we could have engaged with.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Explore how, in this capsule.</span></div>,
    description: "Enable your child to engage positively (e.g. with openness, without any biases) with the different kinds of people they may encounter in their life",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/EngagePositivePpl_icon.png",
    icon_bg: '#B4F7D1',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/EngagePositive_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },BuildingResilience:{
    heading:"Engaging with the Environment",
    title: "Adapt to the environment: Building resilience",
    excerpt: <div>We may not always remain in the safe and familiar confines of our family, home, school, locality. What is our reaction to environments that are new and unfamiliar to us? What if we have to suddenly live in extreme environments that are tough to adjust to? How do we react when things around us are continuously changing and uncertainty is high? <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover our inner power to adapt to the environment and build resilience in the face of ups and downs, in this capsule.</span></div>,
    description: "Build resilience in your child by helping them discover their inner power to adapt to any environment",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/AdaptEnv_icon.png",
    icon_bg: '#9BE589',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Adapt_env_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },LearnBeyondTheClassroom:{
    heading:"Build Learn-abilities",
    title: "Learn beyond the classroom",
    excerpt: <div>All of us learn when we are formally taught – in the classroom or by tuition teachers or parents. But there are opportunities to learn all around us – can we recognize them? Can we become learners all the time, learning everywhere, learning from all aspects of our life? <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Explore how, in this capsule.</span></div>,
    description: "Inspire your child to become a ‘learner’ in all parts of their life",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/Learner_icon.png",
    icon_bg: '#FFCACA',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Learner_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },LearningPathwaysPrimer:{
    heading:"Build Learn-abilities",
    title: "Seven Pathways to Learning: A Primer",
    excerpt: <div>We are often told that we should be a ‘learner’ and learn various things. But what does that mean? How are we to learn? Is there one way to learn everything or are there different pathways to learn?<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover seven pathways that can help you learn, in this capsule.</span></div>,
    description: "Enable your child to discover seven unique pathways that can empower them to learn in very different ways",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/7PathwaysLearn_icon.png",
    icon_bg: '#F2CD72',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/7Pathways_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },SenseMaking:{
    heading:"Build Knowledge Capacities",
    title: "Re-discover knowledge as ‘sense-making’ the world",
    excerpt: <div>Things around us are rapidly changing, becoming increasingly complex - it is not easy to interpret and understand what is going on. <br /> But if you think about it, knowledge actually gives us a power to make sense of the world around us. <br /> Have you seen or experienced knowledge in this light? <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Rediscover the power of knowledge in sense-making the world, in this capsule.</span></div>,
    description: "Enable your child to re-discover the power of knowledge in making sense of the increasingly complex world around them",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/Rediscover_knowledge_ico.png",
    icon_bg: '#9CDBEF',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Rediscover_Knwoledge_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },KnowledgeTraditionsIndia:{
    heading:"Discovering Indian roots",
    title: "Discover the ‘knowledge traditions’ of India",
    excerpt: <div>The knowledge traditions and systems of a civilization is the way in which its people engage with knowledge -  what knowledge means to them, how they learn & develop knowledge in any area,  the methods by which they analyze & assess any new knowledge, how knowledge gets transmitted and shared, etc. <br /> While many of us may have experienced or at least heard of knowledge traditions such as the Greek method of inquiry or the Scientific method of empirical analysis - did you know that the knowledge traditions in India have been very well developed since ancient times? <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover some of these, in this capsule.</span></div>,
    description: "Strengthen your child’s faith in our own roots by enabling them to discover the ‘knowledge traditions’ of India",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/DisKnowTrad_icon.png",
    icon_bg: '#F4D46E',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/KnowTrad_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },TruthSeeker:{
    heading:"Build Knowledge Capacities",
    title: "Being a truth-seeker",
    excerpt: <div>Every one of us has the possibility of becoming a truth-seeker – continuously moving to higher and higher truths. <br /> This aspiration awakens in us the desire to search for the higher truth wherever we are - in our everyday interactions with friends (e.g. what is the real reason why my friend stopped talking to me?), in any activity we do (e.g. is there a better way to do this?), in any information or feedback I receive (e.g. is this really valid for this situation?), in any injustice I witness (e.g. what is the real issue?), in any subject I take up for study, etc. <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover this possibility and get a taste of it, in this capsule.</span></div>,
    description: "Open up the possibility of being a ‘truth-seeker’, to your child i.e. a person who continuously searches for the ‘higher truth’ in any situation, interaction, or issue",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/TruthSeeker_ico.png",
    icon_bg: '#91D8ED',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/Truthseeker_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  },ExpandLimits:{
    heading:"",
    title: "Expand the limits of my world",
    excerpt: <div>The size of ‘our world’ is limited to what we have seen and experienced thus far. When we are small, our world is our home and family. As we grow, our world expands to include school, teachers, friends. <br /> But humankind itself has a tremendous power to keep expanding the limits of our world (e.g. we have scaled the highest mountains, reached the depths of the oceans, gone into outer space) – to go beyond, where no human has gone before. And in that case, so can you!<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> Discover the power to expand the limits of your world, in this capsule. </span></div>,
    description: "Enable your child discover the possibility of expanding the limits of the world as they know it today",
    badges: null,
    icon:"https://s3.ap-south-1.amazonaws.com/possibilities-project/Icons/ExpandLimitsWorld_icon.png",
    icon_bg: '#D0EDB4',
    organisers: organisers.os_002,
    poster:"https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/ExpandLimits_thb.png",
    teaser: null,
    ready: false,
    offering:"A structured session tool that you can use to conduct a 45 min deep exploration with your child"
  }
};

Object.keys(channels).forEach((key, i)=>{
  channels[key].route = mainRoute+"/"+key;
});

export default channels;