// import overview_faceTheBrute from "../Tools/overview_faceTheBrute";
// import overview_sameSightedness from "../Tools/overview_sameSightedness";

// let {posts_001, posts_002} = posts;

export const units = {
    "uo_001": {
      title: 'What does "facing the brutes" mean?',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vQCncCOTIzG_ht65RJT3p6IcbzSdnZf9BASWc_Xu5ojDmEBHNMIAgP87etFJpYislBloEX_LIwnG4sx/embed?start=false&loop=false&delayms=3000',
      time: '[~5 min]'
    },
    "uo_002": {
      title: 'Why face our brutes at all?',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vQfx8Y-t8Q0eKWpPtmdCedlYGR5osHeomSSbXEoFMtkwW06rFcsNF-H0MaqdJ249UQBW7SBeRV5I8of/embed?start=false&loop=false&delayms=3000',
      time: '[~15 min]'      
    },
    "uo_003": {
      title: 'The first step in facing our brutes: Identify the “brutes”',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vRjkdTAAbPpbktImk2Bd8_1LaHGoTAeLn0Q9-30Qei_ywGRH2XFlu4ogocxkjzZAZsd8PxucyAGtKTu/embed?start=false&loop=false&delayms=3000',
      time: '[25 - 30 min]'
    },
    "uo_004": {
      title: 'Facing & overcoming our brutes: Practice the approach',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vTLTNIiRjWI7HBgbx5L4NJqOVnab4Sc5t4ZjDfwixQAB5M99j0nEOWrpJCqOkNJW2Hzzb0ZSdax2HRd/embed?start=false&loop=false&delayms=3000',
      time: '[~20 min]'
    },
    "uo_005":{
      title: 'What is same-sightedness? Explore',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vT4vJpCQU9XBcyWg8AJNxkKbs7EcvEO8jwLfUmdhXxFFvvVEzQA_cx84dRffdc52g-kt69Gh-b0DvX_/embed?start=false&loop=false&delayms=3000',
      time: '[~10 min]'
    },
    "uo_006":{
      title: 'Can you recognize same-sightedness around you?',
      type:'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vSU_3mP4tX_dQuYNUED5c5xJUcVVuss-T6TitdLKJR2u7MFECKC8kRP0UWeIfIhEifUcD9DcQhBDN4p/embed?start=false&loop=false&delayms=3000',
      time: '[~10 min]'
    },
    "uo_007":{
      title: 'How to start practicing same-sightedness in our life?',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vS24OYTyJ05jQNyt26f7PPdrN9mU7kIw-XPu9slaI-sacksocZVpgKnYml5TCT3_ug033hqPp1mfbT1/embed?start=false&loop=false&delayms=3000',
      time: '[~20 min]'
    },
    "uo_008":{
      title: 'Why develop concentration?',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vQK8jU_aNRkYkFuGXV8djFZ_EmLDgeccsSSCT6X7cQ1Gv08wJ7PHElpItM6sSw8pI08pmLKRGEvPldD/embed?start=false&loop=false&delayms=3000',
      time: '[~5 min]'
    },
    "uo_009":{
      title: 'How to develop our concentration? – An approach',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vRQ4TmpxR6faLC8nl2eZzDeasoRny2DkCl67hgBoCnUMXXCkLrAF2lP15pdtPTRAimEIt3iUwFH6wgW/embed?start=false&loop=false&delayms=3000',
      time: '[~40 min]'
    },
    "uo_010":{
      title: 'Introduction',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vTJ3YLlYSAI4eC-Pd2G3cvU8aV9eTsDOZqsYmos-ZpKjiQ8TCYbogM0f95U7ahvqKkhHyHzWZQw6pX_/embed?start=false&loop=false&delayms=60000',
      time: '[~10 min]'
    },
    "uo_011":{
      title: 'Practising discrimination in our day-to-day situations',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vRzA9BrasFoVXE57JgMb-q95yK6C6nJbZqONo_PUtWDpp97e6t7bmjbLT3DEk32S-Z20b84hYUTZoUL/embed?start=false&loop=false&delayms=60000',
      time: '[~10 min]'
    },
    "uo_012":{
      title: 'Practicing discrimination when we take up a project or a long-term goal',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vRZMDpF4RCQmhvD2I4tiJGwXPL-l7JmnBlFw1pcTrX6T0-5Nl0S-4kH4gQTlsOBfV7jMQeGvSA4ibIq/embed?start=false&loop=false&delayms=60000',
      time: '[~10 min]'
    },
    "uo_013":{
      title: 'Summary',
      type: 'ppt',
      src: 'https://docs.google.com/presentation/d/e/2PACX-1vSjNC2bOTevuWrkqXF76RRlmM_Cu-8DmW9mlN7mWgSIYlI7EGz5mCusX1Owvn-r69tjxQhM2jwjtGBr/embed?start=false&loop=false&delayms=60000',
      time: '[~10 min]'
    }
}