import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";
// let server =
// "http://localhost:5001/possibilities-parenting/us-central1/serverResponse";
const config = {
  apiKey: "AIzaSyAFD02XskxCcbdTz_mlK4kKtO8motJsNCE",
  authDomain: "possibilities-parenting.firebaseapp.com",
  databaseURL: "https://possibilities-parenting.firebaseio.com",
  projectId: "possibilities-parenting",
  storageBucket: "possibilities-parenting.appspot.com",
  messagingSenderId: "1009512559846"
};

firebase.initializeApp(config);

export const loginAttempt = ({email, password, successCallBack, failureCallback}) => {
  return dispatch => {
    dispatch({type: "LOGIN_ATTEMPT", payload: true});
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          loginAttempSuccess(dispatch, user, successCallBack);
        })
        .catch(err => {
          let errMsg = getErrorMsg(err);
          loginAttempFail(dispatch, errMsg, failureCallback);
          setTimeout(() => {
            dispatch({type: "SET_LOGIN_ERROR_MSG", payload: " "});
          }, 3000);
        });
    }
  };
};

firebase
  .auth()
  .onAuthStateChanged(function (user) {
    if (user) {
      let uid = user.uid;
      firebase
        .database()
        .ref(`/users/${uid}`)
        .on('value', userSnapshot => {
          let userData = userSnapshot.val();
          store.dispatch({type: "SYNC_LOCAL_FROM_GLOBAL", payload: userData});
        });
    } else {
      //console.log("no user");
    }
  });

  export const registerAttempt = ({
  email,
  password,
  firstName,
  phoneNumber,
  successCallBack,
  failureCallback
}) => {
  return dispatch => {
    dispatch({type: "REGISTER_ATTEMPT", payload: true});
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(err => {
        loginAttempFail(dispatch, err, failureCallback);
      })
      .then(user => {
        //Send a Verification mail to the user
        firebase
          .auth()
          .currentUser
          .sendEmailVerification()
          .catch(error => {});

        let updates = {
          email,
          firstName,
          phoneNumber
        };
        firebase
          .database()
          .ref(`/users/${user.user.uid}/userAccount`)
          .update(updates)
          .catch(err => {
            console.log("user profile update failed")
          });

        loginAttempSuccess(dispatch, user, successCallBack);
      });
  };
};

export const setErrMsg = el => {
  return dispatch => {
    dispatch({type: "SET_LOGIN_ERROR_MSG", payload: el});
  };
};

export const resetPassword = email => {
  var auth = firebase.auth();
  var emailAddress = email;
  return dispatch => {
    if (email) {
      dispatch({type: "PASSWORD_RESET_IN_PROGRESS", payload: true});
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          dispatch({type: "RESET_PASSWORD_LINK_SENT", payload: true});
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  };
};

export const updateUserDetails = user => {
  return dispatch => {
    dispatch({type: "UPDATE_USER_UID", payload: user.uid});
  };
};

export const resetLoginPage = () => {
  return dispatch => {
    dispatch({type: "RESET_LOGIN_PAGE"});
  };
};

export const resetForgotPasswordPage = () => {
  return dispatch => {
    dispatch({type: "RESET_FORGOT_PASSWORD_PAGE"});
  };
};

export const registerNewUserPage = () => {
  return dispatch => {
    dispatch({type: "RESET_REGISTER_NEW_USER_PAGE"});
  };
};

export const signOut = () => {
  let auth = firebase.auth();
  return dispatch => {
    console.log("ran");
    auth
      .signOut()
      .then(function () {
        dispatch({type: "LOGOUT_ATTEMPT"})
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

/**Helper Functions**/
const loginAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({type: "LOGIN_SUCCEEDED", payload: user});
  successCallBack();
};

const loginAttempFail = (dispatch, err, failureCallback, pos) => {
  dispatch({type: "LOGIN_FAILED", payload: err.message});
  failureCallback();
};

function getErrorMsg(error) {
  let {code} = error;

  switch (code) {
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message = "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg});

  setTimeout(() => {
    dispatch({type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " "});
  }, 3000);
}
/**End of Helper Functions**/
