import React, {Component} from "react";

let PaymentResponse = class PaymentResponse extends Component{

  componentDidMount(){
    let txnid = this.props.match.params.txnid;
    fetch('http://localhost:5001/possibilities-parenting/us-central1/serverResponse/paymentResponse', {
      method: 'POST',
      body: JSON.stringify({txnid}),
      headers: { "Content-Type": "application/json" }
    }).catch(err => {
      console.log(err);
    }).then(response => response.json().then(response=>{
      console.log(response);
    }));
  }

  render(){
    
    return <div>Your Payment was Successful</div>
  }
}

export default PaymentResponse;